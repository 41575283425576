import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import App from './App';
import { Layout } from './components/Layout/Layout';
import { ContentPage } from './pages/PrivacyPolicy/ContentPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Layout />}>
        <Route path='/' element={<App />} />
        <Route
          path='/policy'
          element={<ContentPage pageName='Privacy policy' />}
        />
        <Route
          path='/terms'
          element={<ContentPage pageName='Terms of Use' />}
        />
      </Route>
    </Route>
  )
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
