import React, { FC } from 'react';

import styles from './AboutUsCard.module.css';

interface AboutUsCardProps {
  headerText: string;
  text: string;
  img: string;
}
export const AboutUsCard: FC<AboutUsCardProps> = ({
  text,
  headerText,
  img,
}) => {
  const containerStyle = {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <div className={styles.aboutUsCard} style={containerStyle}>
      <div className={styles.textContent}>
        <h2>{headerText}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};
