import React, { FC, InputHTMLAttributes } from 'react';

import styles from './Input.module.css';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
}
export const Input: FC<InputProps> = ({ placeholder, ...props }) => {
  return (
    <input {...props} className={styles.input} placeholder={placeholder} />
  );
};
