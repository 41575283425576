import React, { FC, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import styles from './ContentPage.module.css';

interface ContentPageProps {
  pageName: string;
}
export const ContentPage: FC<ContentPageProps> = ({ pageName }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);
  return (
    <div className={styles.policyWrapper}>
      <h1>{pageName}</h1>
      <div className={styles.policyContent}>
        {' '}
        <p>
          We collect Personally Identifiable Information from you only if it is
          specifically and knowingly provided by you. “Personally Identifiable
          Information” is information that would allow someone to identify or
          contact you, including, for example, your name, address, phone number
          and e-mail address. You are not required to provide Personally
          Identifiable Information to visit this Website, but you will need to
          provide it if you sign up for our email updates, apply for a job or
          make a donation through our Website. When you make a donation through
          the Website, you are redirected to an independent third party to
          process the donation. That third party collects your Personally
          Identifiable Information, using its own service providers to complete
          the transaction, and transmits the payment or donation to us, along
          with your name, contact information and other relevant details you
          provided in connection with the transaction.
        </p>
        <br />
        <p>
          We collect Personally Identifiable Information from you only if it is
          specifically and knowingly provided by you. “Personally Identifiable
          Information” is information that would allow someone to identify or
          contact you, including, for example, your name, address, phone number
          and e-mail address. You are not required to provide Personally
          Identifiable Information to visit this Website, but you will need to
          provide it if you sign up for our email updates, apply for a job or
          make a donation through our Website. When you make a donation through
          the Website, you are redirected to an independent third party to
          process the donation. That third party collects your Personally
          Identifiable Information, using its own service providers to complete
          the transaction, and transmits the payment or donation to us, along
          with your name, contact information and other relevant details you
          provided in connection with the transaction.
        </p>
        <br />
        <p>
          We collect Personally Identifiable Information from you only if it is
          specifically and knowingly provided by you. “Personally Identifiable
          Information” is information that would allow someone to identify or
          contact you, including, for example, your name, address, phone number
          and e-mail address. You are not required to provide Personally
          Identifiable Information to visit this Website, but you will need to
          provide it if you sign up for our email updates, apply for a job or
          make a donation through our Website. When you make a donation through
          the Website, you are redirected to an independent third party to
          process the donation. That third party collects your Personally
          Identifiable Information, using its own service providers to complete
          the transaction, and transmits the payment or donation to us, along
          with your name, contact information and other relevant details you
          provided in connection with the transaction.
        </p>
        <br />
        <p>
          We collect Personally Identifiable Information from you only if it is
          specifically and knowingly provided by you. “Personally Identifiable
          Information” is information that would allow someone to identify or
          contact you, including, for example, your name, address, phone number
          and e-mail address. You are not required to provide Personally
          Identifiable Information to visit this Website, but you will need to
          provide it if you sign up for our email updates, apply for a job or
          make a donation through our Website. When you make a donation through
          the Website, you are redirected to an independent third party to
          process the donation. That third party collects your Personally
          Identifiable Information, using its own service providers to complete
          the transaction, and transmits the payment or donation to us, along
          with your name, contact information and other relevant details you
          provided in connection with the transaction.
        </p>
        <br />
        <p>
          We collect Personally Identifiable Information from you only if it is
          specifically and knowingly provided by you. “Personally Identifiable
          Information” is information that would allow someone to identify or
          contact you, including, for example, your name, address, phone number
          and e-mail address. You are not required to provide Personally
          Identifiable Information to visit this Website, but you will need to
          provide it if you sign up for our email updates, apply for a job or
          make a donation through our Website. When you make a donation through
          the Website, you are redirected to an independent third party to
          process the donation. That third party collects your Personally
          Identifiable Information, using its own service providers to complete
          the transaction, and transmits the payment or donation to us, along
          with your name, contact information and other relevant details you
          provided in connection with the transaction.
        </p>
      </div>
    </div>
  );
};
