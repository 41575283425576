import React from 'react';

import { useForm, ValidationError } from '@formspree/react';

import styles from './ContactBlock.module.css';

import contactImg from '../../assets/contactImage.png';
import { socialMediaData } from '../../data';
import { Button } from '../../ui/Button/Button';
import { Input } from '../../ui/Input/Input';
import { SectionHeader } from '../../ui/SectionHeader/SectionHeader';
import { TextArea } from '../../ui/TextArea/TextArea';
export const ContactBlock = () => {
  const [state, handleSubmit] = useForm('xoqozrge');
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.contactBlock} id='contact'>
        <SectionHeader text='contact' />
        <hr />
        <div className={styles.contentWrapper}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formContent}>
              <h3>
                You can also use <br />
                the form bellow
              </h3>
              <Input
                placeholder='You name'
                id='email'
                type='email'
                name='email'
              />
              <ValidationError
                prefix='Email'
                field='email'
                errors={state.errors}
              />
              <Input placeholder='You email' />
              <TextArea placeholder='You message' id='message' name='message' />
              <ValidationError
                prefix='Message'
                field='message'
                errors={state.errors}
              />
            </div>
            <Button
              text='Send a message'
              type='submit'
              disabled={state.submitting}
            />
          </form>
          <div className={styles.socialContent}>
            <h3>Social networks</h3>
            <div className={styles.socialItems}>
              {socialMediaData.map((item) => (
                <a
                  href={item.href}
                  target='_blank'
                  className={styles.socialMediaItem}
                  key={item.id}
                  rel='noreferrer'
                >
                  <img src={item.img} alt='linkImg' />
                  <p>{item.text}</p>
                </a>
              ))}
            </div>
          </div>
        </div>
        <hr />

        <img src={contactImg} alt='stone' className={styles.contactImg} />
      </div>
    </div>
  );
};
