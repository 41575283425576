import React, { FC } from 'react';

import styles from './SectionHeader.module.css';

import { useMobileScreen } from '../../hooks/useMobileScreen';

type alignVariant = 'center' | 'initial';
interface SectionHeader {
  text: string;
  textAlign?: alignVariant;
}
export const SectionHeader: FC<SectionHeader> = ({ text, textAlign }) => {
  const isMobileScreen = useMobileScreen(1279);

  return (
    <h2
      style={!isMobileScreen ? { textAlign: textAlign } : {}}
      className={styles.sectionHeader}
    >
      {text}
    </h2>
  );
};
