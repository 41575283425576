import React, { FC } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Footer.module.css';

import inst from '../../assets/svg/socialMedia/inst.svg';
import tiktok from '../../assets/svg/socialMedia/tiktok.svg';
import youtube from '../../assets/svg/socialMedia/youtube.svg';

interface FooterProps {
  isInBurgerMenu: boolean;
}
export const Footer: FC<FooterProps> = ({ isInBurgerMenu }) => {
  return (
    <footer
      className={classNames(styles.footer, {
        [styles.burgerFooter]: isInBurgerMenu,
      })}
    >
      <p>© 2023 Grootek</p>
      <div className={styles.socialMedia}>
        <a
          target='_blank'
          href='https://www.youtube.com/@grootekgame'
          rel='noreferrer'
        >
          <img src={youtube} alt='youtube' />
        </a>
        <a
          target='_blank'
          href='https://www.instagram.com/grootekgame'
          rel='noreferrer'
        >
          <img src={inst} alt='inst' />
        </a>
        <a
          target='_blank'
          href='https://www.tiktok.com/@grootekgame'
          rel='noreferrer'
        >
          <img src={tiktok} alt='tiktok' />
        </a>
      </div>
      <div className={styles.links}>
        <Link to='/policy'>Privacy policy</Link>
        <Link to='/terms'>Terms of Use</Link>
      </div>
    </footer>
  );
};
