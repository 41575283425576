import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import styles from './Layout.module.css';

import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
export const Layout = () => {
  const { pathname } = useLocation();
  const contentPagesLinks = ['/policy', '/terms'];
  return (
    <main
      className={`${styles.layout} ${
        contentPagesLinks.includes(pathname) ? styles.whiteLayout : ''
      }`}
    >
      <Header />
      <Outlet />
      <Footer isInBurgerMenu={false} />
    </main>
  );
};
