import React from 'react';

import styles from './InformationBlock.module.css';

import infoImg from '../../assets/informationIMG.png';
import { informationData } from '../../data';
import { SectionHeader } from '../../ui/SectionHeader/SectionHeader';
import { TextCard } from '../../ui/TextCard/TextCard';
export const InformationBlock = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.informationBlock}>
        <SectionHeader text='Information' />
        <div className={styles.content}>
          <img src={infoImg} alt='info img' />
          <div className={styles.cardsContainer}>
            {informationData.map((item, index) => (
              <TextCard
                key={item.id}
                headerText={item.headerText}
                cardText={item.cardText}
                isLastCard={item.id === index + 1}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
