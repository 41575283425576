import { useLayoutEffect, useState } from 'react';

export const useMobileScreen = (size: number) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobileScreen = screenWidth <= size;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobileScreen;
};
