import goal from '../assets/goalCard.png';
import mission from '../assets/missionCard.png';
import gmail from '../assets/svg/socialMedia/gmail.svg';
import inst from '../assets/svg/socialMedia/inst.svg';
import tiktok from '../assets/svg/socialMedia/tiktok.svg';
import twitter from '../assets/svg/socialMedia/twitter.svg';
import youtube from '../assets/svg/socialMedia/youtube.svg';
import values from '../assets/valuesCard.png';
export const informationData = [
  {
    id: 1,
    headerText: 'Educational value ',
    cardText:
      'We focus on key early learning concepts including colors, shapes, and numbers to provide a strong foundation.',
  },
  {
    id: 2,
    headerText: 'Safety features',
    cardText:
      'You can rest assured that your child is playing in a safe, ad-free environment.',
  },
  {
    id: 3,
    headerText: 'Parental controls',
    cardText:
      'We understand the importance of a safe and ad-free environment for your child. Our game is designed with parentalcontrols, ensuring a worry-free experience.',
  },
];
export const aboutUsData = [
  {
    id: 1,
    headerText: 'Creators Background  ',
    cardText:
      "We are a young team of children's game developers. Our goal is to expand the child's horizons, to help the child to learn the world in a game form with the help of parents or independently.",
  },
  {
    id: 2,
    headerText: 'EducaT Approach',
    cardText:
      'In our game children will learn: to distinguish between seasons, to distinguish colors and sizes of objects, to distinguish fruits and vegetables, to find certain objects among many other objects. They will also develop their fine motor skills and logical thinking.',
  },
  {
    id: 3,
    headerText: 'Content Curation',
    cardText:
      'The best developers, artists, musicians are working on our game. All of us work for the benefit of you and your children.',
  },
];

export const socialMediaData = [
  {
    id: 1,
    img: gmail,
    text: 'info@grootek.com',
    href: 'mailto:info@grootek.com',
  },
  {
    id: 2,
    img: inst,
    text: '@grootekgame',
    href: 'https://www.instagram.com/grootekgame',
  },
  {
    id: 3,
    img: tiktok,
    text: '@grootekgame',
    href: 'https://www.tiktok.com/@grootekgame',
  },
  {
    id: 4,
    img: twitter,
    text: '@grootekgame',
    href: 'https://www.twitter.com/@grootekgame',
  },
  {
    id: 5,
    img: youtube,
    text: '@grootekgame',
    href: 'https://www.youtube.com/@grootekgame',
  },
];

export const aboutUsCardsData = [
  {
    id: 1,
    headerText: 'Goal',
    text: "Meaningfulness — relevant to real life and closely connected to the child's everyday life",
    img: goal,
  },
  {
    id: 2,
    headerText: 'Mission',
    text: 'Formation and development of digital thinking',
    img: mission,
  },
  {
    id: 3,
    headerText: 'Values',
    text: 'Meaningfulness, interaction with society, blocking unwanted content',
    img: values,
  },
];
