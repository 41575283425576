/* eslint-disable react/no-unescaped-entities, jsx-a11y/alt-text */
import React from 'react';

import styles from './Hero.module.css';

import heroImage from '../../assets/heroImage.png';
import appStore from '../../assets/svg/appStore.svg';
import googlePlay from '../../assets/svg/googlePlay.svg';
export const Hero = () => {
  return (
    <div className={styles.hero} id='home'>
      <div className={styles.textContent}>
        <h1>Grootek</h1>
        <h2>
          children's <br />
          game <br />
          development <br />
          team <br />
        </h2>
      </div>
      <img src={heroImage} className={styles.heroImg} />
      <div className={styles.services}>
        <img src={appStore} alt='app Store' />
        <img src={googlePlay} alt='google play' />
      </div>
    </div>
  );
};
