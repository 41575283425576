import React from 'react';

import styles from './AboutUsBlock.module.css';

import { aboutUsCardsData, aboutUsData } from '../../data';
import { AboutUsCard } from '../../ui/AboutUsCard/AboutUsCard';
import { SectionHeader } from '../../ui/SectionHeader/SectionHeader';
import { TextCard } from '../../ui/TextCard/TextCard';
import { Wrapper } from '../Wrapper/Wrapper';
export const AboutUsBlock = () => {
  return (
    <div className={styles.aboutUsContainer} id='about'>
      <Wrapper>
        <div className={styles.aboutUsBlock}>
          <SectionHeader text='about us' textAlign='center' />
          <div className={styles.cardsContainer}>
            {aboutUsCardsData.map((card) => (
              <AboutUsCard
                key={card.id}
                headerText={card.headerText}
                text={card.text}
                img={card.img}
              />
            ))}
          </div>
          {aboutUsData.map((item, index) => (
            <TextCard
              key={item.id}
              isInAbout
              headerText={item.headerText}
              cardText={item.cardText}
              isLastCard={item.id === index + 1}
            />
          ))}
        </div>
      </Wrapper>
    </div>
  );
};
