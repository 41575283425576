import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './TextCard.module.css';

interface TextCardProps {
  headerText: string;
  cardText: string;
  isLastCard: boolean;
  isInAbout?: boolean;
}
export const TextCard: FC<TextCardProps> = ({
  headerText,
  cardText,
  isLastCard,
  isInAbout,
}) => {
  return (
    <div
      className={classNames(styles.textCard, {
        [styles.lastCard]: isLastCard,
        [styles.aboutUsTextCard]: isInAbout,
      })}
    >
      <h3>{headerText}</h3>
      <p>{cardText}</p>
    </div>
  );
};
