import React from 'react';

import { AboutUsBlock } from './components/AboutUsBlock/AboutUsBlock';
import { ContactBlock } from './components/ContactBlock/ContactBlock';
import { Hero } from './components/Hero/Hero';
import { InformationBlock } from './components/InformationBlock/InformationBlock';
function App() {
  return (
    <>
      <Hero />
      <InformationBlock />
      <AboutUsBlock />
      <ContactBlock />
    </>
  );
}

export default App;
