import React, { FC, TextareaHTMLAttributes } from 'react';

import styles from './TextArea.module.css';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder: string;
}
export const TextArea: FC<TextAreaProps> = ({ placeholder }) => {
  return <textarea placeholder={placeholder} className={styles.textarea} />;
};
