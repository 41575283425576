/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './Header.module.css';

import burgerMenuImage from '../../assets/burgerMenuImage.png';
import logo from '../../assets/logo.svg';
import burger from '../../assets/svg/burger.svg';
import close from '../../assets/svg/close.svg';
import { Footer } from '../Footer/Footer';
export const Header = () => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  useEffect(() => {
    if (isBurgerOpen) {
      document.documentElement.classList.add(styles.lockScroll);
    }
    return () => document.documentElement.classList.remove(styles.lockScroll);
  }, [isBurgerOpen]);
  return (
    <header className={styles.header}>
      <img src={logo} alt='logo' />
      <nav
        className={classNames(styles.navbar, {
          [styles.burgerNavbar]: isBurgerOpen,
        })}
      >
        <div className={styles.navbarContent}>
          <a href='/#home' role='button' onClick={() => setIsBurgerOpen(false)}>
            home
          </a>
          <a
            href='/#about'
            role='button'
            onClick={() => setIsBurgerOpen(false)}
          >
            about us
          </a>
          <a
            href='/#contact'
            role='button'
            onClick={() => setIsBurgerOpen(false)}
          >
            contact
          </a>
        </div>
        <img alt='stone' className={styles.burgerImage} src={burgerMenuImage} />
        <Footer isInBurgerMenu />
      </nav>
      <img
        alt='burger'
        className={styles.burgerButton}
        src={isBurgerOpen ? close : burger}
        role='button'
        onClick={() => setIsBurgerOpen(!isBurgerOpen)}
      />
    </header>
  );
};
